<template>
  <div class="wrap">
    <div class="overlay">
      <a v:if="deeplinkUrl" @click="handleToCumenApp" :href="deeplinkUrl">
        <img class="overlay_img" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01906d08-e228-7540-1a4a-03c23df0faca" />
      </a>
      </div>
    <div class="top">
      <div class="ips" />
      <div class="text" />
    </div>
    <view class="content">
      <view class="invitor">
        <img :src="invitor.avatarUrl" class="avatar" />
        <div class="info"><span class="nickname">{{ invitor.nickname }}</span> 喊你一起</div>
      </view>
      <div class="team-name">{{ team.name }}</div>
      <div class="team-total-member">
        <div class="team-total-member-decorator" />
          <div class="team-total-member-text"><span>{{ team.total_member }}</span> 个朋友等你一起来</div>
        <div class="team-total-member-decorator" />
      </div>
      <div class="team-member-wrap">
        <div v-for="(user, index) in team.members.slice(0, 10)" :key="user.id" class="team-member">
          <img  v-if="index == 9 && team.members.length > 9" class="more-member-icon" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01906da3-0f08-d293-4c20-4f6d97cb316f" />
          <img :src="user.avatarUrl" class="avatar" />
        </div>
      </div>
      <a v:if="deeplinkUrl" @click="handleToCumenApp" :href="deeplinkUrl"><img src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01906daa-488f-b8de-2e53-9dd3a8db09e1" class="launch-app-btn" /></a>
		<!-- 引导默认浏览器打开网页 -->
		<guide-app v-model:showGuide="showGuide" />
    <alert v-model:showModal="showModal" @confirm="handleToDownloadPage" content="下载粗门App" />
    </view>
  </div>
</template>
<script>
import $fetch from "@/utils/fetch.js";
import Toast from "@/components/Toast.vue";
import GuideApp from "@/components/GuideApp.vue";
import Alert from "@/components/Alert.vue";
export default {
  name: "RouteTeamInvitation",
  components: {
		GuideApp,
		Alert,
  },
  data() {
     return {
       showGuide: false,
       loading: true,
       loadError: false,
       isWechatBrowser: false,
       isIOS: false,
       deeplinkUrl: '',
       showModal: false,
       isAndroid: false,
       invitor: {
         avatarUrl: '',
         nickname: ''
       },
       team: {
         name: "",
         total_member: 0,
         members: [ ]
       }
    };
  },
  created() {
		this.isWechatBrowser =
			window.navigator.userAgent
				.toLowerCase()
				.indexOf("micromessenger") !== -1;
		this.showGuide = this.isWechatBrowser;
		const { userAgent } = window.navigator;
		this.isIos = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
		this.isAndroid =
			userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1;
    const { token } = this.$route.query;
    const param = encodeURIComponent(JSON.stringify({ "invitationToken": token }));
    const deeplinkUrl = `cumen://app.cumen.fun//?tabIndex=1&action=${param}`;
    this.deeplinkUrl = deeplinkUrl;
    if (!token) {
      throw new Error('页面参数缺少token');
    }
    this.load(token);
  },
  methods: {
    async load(token) {
      try {
        this.loading = true;
        const res = await $fetch.post("xx.cumen.route.team.v1.TeamService/GetTeamByInvitationToken", {invitationToken: token}, {}, "https://api.cumen.fun/api/");
        console.log(res);
        const {invitor, member_avatar_urls, member_count, name} = res;
        this.invitor = {
          nickname: invitor.nickname,
          avatarUrl: invitor.avatar_url
        }
        this.team.name = name;
        this.team.total_member = member_count;
        this.team.members = [
           // 第一个头像是一个默认加号
           { avatarUrl: 'https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01906d9d-f4d2-b0c9-a5b7-6f800316b8ae' },
          ...member_avatar_urls.map(url => ({ avatarUrl: url }))
        ]
      } catch (error) {
        this.loadError = true;
      } finally {
        this.loading = false;
      }
    },
    handleToCumenApp() {
      if (this.isWechatBrowser) {
        this.showGuide = true;
      } else {
        this.showModal = true;
      }
    },
    handleToDownloadPage() {
      this.showModal = false;
      location.href = this.isIos 
        ? "https://apps.apple.com/app/%E7%B2%97%E9%97%A8/id1593823623" 
        : "https://sj.qq.com/appdetail/tech.xiaoxiang.emily";
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
body {
  background: rgba(38, 38, 38, 1);
}
.wrap {
  font-size: 16px;
  background: no-repeat url('https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01906cfc-c4e6-5b7e-fe1e-b21ba2a13795') rgba(38, 38, 38, 1);
  background-size: 100% px2rem(1644);
  background-position: center px2rem(-170);
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: start;
  .overlay {
    // position: absolute;
    // top: 0;
    width: 100%;
    height: px2rem(144);
    background: #fff;
    background-size: 100% 100%;
    .overlay_img {
      z-index: 999;
      height: px2rem(144);
      width: 100%;
    }
  }
  .top {
    width: 100%;
    position: relative;
    height: px2rem(768);
    .ips {
      position: absolute;
      top: px2rem(272);
      left: px2rem(160);
      width: px2rem(492);
      height: px2rem(514);
      background: no-repeat url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01906d0b-9049-668f-8911-0f7756226dd5");
      background-size: 100% 100%;

    }
    .text {
      position: absolute;
      top: px2rem(212);
      left: px2rem(208);
      width: px2rem(474);
      height: px2rem(192);
      background: no-repeat url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01906d28-3c11-e27d-bc2c-ae74b688ca27");
      background-size: 100% 100%;
    }
  }
  .content {
    position: relative;
    top: px2rem(-130);
    .invitor {
      display: flex;
      flex-direction: column;
      align-items: center;
      .avatar {
        object-fit: cover;
        width: px2rem(144);
        height: px2rem(144);
        border-radius: px2rem(18);
      }
      .info {
        margin-top: px2rem(16);
        color: #fff;
        font-size: px2rem(28);
        font-family: "Alibaba PuHuiTi";
        font-weight: normal;
        .nickname {
          font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi, PingFangSC-Semibold;
          font-weight: bold;
        }
      }
    }
    .team-name {
      color: #1FFD91;
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi, PingFangSC-Semibold;
      text-stroke: px2rem(2) black;
      font-size: px2rem(40);
      text-align: center;
      margin-top: px2rem(16);
    }
    .team-total-member {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: px2rem(62);
      margin-bottom: px2rem(24);
      .team-total-member-text {
        color: #fff;
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi, PingFangSC-Semibold;
        margin-left: px2rem(16);
        margin-right: px2rem(16);
        span {
          font-size: px2rem(36);
        }
      }
      .team-total-member-decorator {
        background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/01906d7d-41eb-2742-1711-1e85cf13ece3");
        background-size: 100% 100%;
        width: px2rem(144);
        height: px2rem(1);
      }
    }
    .team-member-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-left: px2rem(96);
      margin-right: px2rem(96);
      justify-content: flex-start;
      .team-member {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: px2rem(39);
        margin-bottom: px2rem(24);
        &:nth-child(5n) {
          margin-right: 0;
        }
        .avatar {
          width: px2rem(80);
          height: px2rem(80);
          border-radius: 100%;
        }
        .more-member-icon {
          position: absolute;
          width: px2rem(40);
          height: px2rem(8);
        }
      }

    }
    .launch-app-btn {
      margin-top: px2rem(48);
      margin-left: auto;
      margin-right: auto;
      width: px2rem(462);
      height: px2rem(88);
    }
  }
}
</style>
