<template>
	<div class="shadow" v-show="showModal">
		<div class="modal">
			<div class="content">{{ content }}</div>
			<div class="btn-group">
				<div class="cancel" @click="hideModal">取消</div>
				<div class="confirm" @click="handleConfirm">确定</div>
			</div>
		</div>
	</div>
	<!-- lottie loading -->
	<loading v-model:showLoading="showLoading" />
</template>

<script>
import Loading from "@/components/Loading.vue";
export default {
	name: "Alert",
	components: {
		Loading,
	},
	props: {
		content: {
			type: String,
			value: "",
		},
		showModal: {
			type: Boolean,
			value: false,
		},
	},
	data() {
		return {
			showLoading: false,
		};
	},
	methods: {
		hideModal() {
			this.$emit("update:showModal", false);
		},
    handleConfirm() {
      this.$emit("confirm");
    },
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.shadow {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 999;
	.modal {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: calc(100vw - px2rem(200));
		background-color: #fff;
		z-index: 999;
		border-radius: px2rem(16);
		overflow: hidden;
		> .content {
			padding: px2rem(72) px2rem(32);
			color: #333;
			font-size: px2rem(32);
			line-height: px2rem(48);
			text-align: center;
			font-family: $PingFangSC-Regular;
			font-weight: bold;
		}
		> .btn-group {
			@include row();
			width: 100%;
			border-top: 1px solid #f5f5f5;
			.cancel,
			.confirm {
				width: 50%;
				height: px2rem(96);
				line-height: px2rem(96);
				font-size: px2rem(32);
				color: #333;
				text-align: center;
				font-weight: bold;
				font-family: $PingFangSC-Regular;
			}
			.confirm {
				background-color: $theme-color-green;
			}
		}
	}
}
</style>
